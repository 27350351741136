<template>
  <v-app>
    <!-- <checkin-drawer /> -->
    <chekin-bar />
    <v-main>
      <!-- <v-main :class="$vuetify.theme.dark ? '' : 'white'"> -->
      <router-view />
    </v-main>
    <core-footer
      :absolute="false"
      :show-to-top="true"
      :showlink="false"
    />
  </v-app>
</template>
<script>
export default {
  components: {
    ChekinBar: () => import("@/views/App/CollegeCheckin/components/ChekinBar"),
    // CheckinDrawer: () => import("@/views/App/CollegeCheckin/components/CheckinDrawer"),
    CoreFooter: () => import("@/views/App/components/core/Footer")
  }
};
</script>